:root {
	--Galaxy: #1F3C4F;
	--Ahsoka: #5F7286;
	--R2D2: #1379CE;
	--Lightsaber: #D0E4F5;
	--Lightsaber45: #E8F3FB;
	--Nebula: #444BAA;
	--ANewHope: #DEDBEE;
	--BobaFett: #047C62;
	--Yoda: #CDE5E0;
	--C3PO: #FDBE03;
	--Rey: #FEECB3;
	--BB8: #DE5D00;
	--Tatooine: #F5CEB3;
	--DarthMaul: #C20027;
	--Sith: #F3CCD2;
	--DarthVader: #000000;
	--Skywalker: #2C3539;
	--Mandalorian: #434F56;
	--DeathStar: #5C6B75;
	--TIEFighter: #BEC4CA;
	--MillenniumFalcon: #D8DBDF;
	--Tauntaun: #EEEFF1;
	--Leia: #FAFAFB;
	--Stormtrooper: #FFFFFF;
}

.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.navbar {
  background-color: var(--Galaxy);
  height: 60px;
}
.navbar .title {
  color: var(--Stormtrooper);
  font-weight: 500;
  font-size: x-large;
}
